import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	ADD_CHOICES: 'add-choices',
	SHOW_MOBILE_DETAILS: 'show-mobile-details',
	PDF_FIT: 'pdf-fit',
	SELECTION_START: 'selection-start',
	SELECTION_STOP: 'selection-stop',
	DELETE_SELECTION: 'delete-selection',
	ADD_FOLDER: 'add-folder',
	BLUR_SEARCH_INPUT: 'blur-search-input',
	preview: {
		OPEN: 'preview-open',
		CLOSE: 'preview-close',
		OPEN_IN_NEW_TAB: 'preview-open-new-tab',
		SHOW_SIDE_BAR: 'preview-show-side-bar',
		HIDE_SIDE_BAR: 'preview-hide-side-bar',
		PREVIOUS_DOCUMENT: 'preview-previous-document',
		NEXT_DOCUMENT: 'preview-next-document',
		PREVIOUS_PAGE: 'preview-previous-page',
		NEXT_PAGE: 'preview-next-page',
		DOCUMENT_RELOADED: 'document-reloaded',
		TOGGLE_THUMBNAILS: 'preview-toggle-thumbnails',
		ZOOM_FIT: 'preview-zoom-fit',
		ZOOM_IN: 'preview-zoom-in',
		ZOOM_OUT: 'preview-zoom-out',
		ROTATE_DOCUMENT: 'rotate-document',
		ROTATE_DOCUMENT_PAGE: 'rotate-document-page',
		DELETE_DOCUMENT_PAGE: 'delete-document-page'
	},
	DOCUMENT_OPENED: 'document-opened',
	DOCUMENT_DOWNLOADED: 'document-downloaded',
	DOCUMENT_DELETED: 'document-deleted',
	RENAME_SELECTION: 'rename-selection',
	DELETION_STARTED: 'deletion-started',
	DELETION_ENDED: 'deletion-ended',
	RESETING_COUNTER: 'reseting-counter',
	RESETING_COUNTER_ENDED: 'reseting-counter-ended',
	RESET_COUNTER: 'reset-counter',
	TOGGLE_VALIDATION: 'toggle-validation'
})
