<template>
	<w-layout :class="{ 'w-loading-container': loading }" :align-center="loading" column fill-height :justify-center="loading">
		<v-progress-circular v-if="loading" color="primary" indeterminate />
		<w-flex v-else scroll-y>
			<v-treeview
				activatable
				:active="active"
				active-class="selectedElement"
				:items="folders"
				item-children="folders"
				item-key="id"
				:load-children="loadChildren"
				return-object
			>
				<template v-slot:prepend="{ open, item }">
					<w-flex shrink @click.stop="selectItem(item)">
						<v-icon class="pointer" color="primary">
							{{ open ? 'far fa-folder-open' : 'fas fa-folder' }}
						</v-icon>
					</w-flex>
				</template>
				<template v-slot:label="{ item }">
					<w-layout class="pointer" justify-start @click.stop="selectItem(item)">
						<v-flex pr-1>{{ item.name }}</v-flex>
					</w-layout>
				</template>
			</v-treeview>
		</w-flex>
		<w-flex v-if="hint && folder && folder.id" pl-4>
			<w-text-info
				:text="
					$t('documents.folder_selected_info', {
						folder_name: folder.name
					})
				"
			/>
		</w-flex>
	</w-layout>
</template>
<script>
// TODO: migrate to DocumentsManagerService
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'FolderSelector',
	mixins: [DocumentsManagerModuleGuard],
	inject: {
		form: {
			default: null
		}
	},
	props: {
		hint: {
			type: Boolean,
			required: false,
			default: true
		},
		required: {
			default: false,
			required: false,
			type: Boolean
		},
		returnObject: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Object, Number],
			required: false,
			default: null
		},
		virtualFolders: {
			type: Boolean,
			required: false,
			default: true
		},
		vendorIdFromParent: {
			required: false,
			type: Number,
			default: () => undefined
		},
		readOnlyFolders: {
			default: true,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			folder: {},
			folders: [],
			loading: false,
			currentVendorId: null
		}
	},
	computed: {
		active: function () {
			if (!this.value) {
				return []
			}
			if (typeof this.value === 'number') {
				return [{ id: this.value }]
			}
			return [this.value]
		},
		hasError: function () {
			let result = false
			if (this.required && !this.value) {
				result = true
			}
			return result
		}
	},
	mounted: function () {
		if (!this.vendorId) {
			this.currentVendorId = this.vendorIdFromParent
		} else {
			this.currentVendorId = this.vendorId
		}
		if (this.currentVendorId) {
			this.listRootFolders(this.currentVendorId)
		}

		if (this.form) {
			this.form.register(this)
		}
	},
	methods: {
		loadChildren: async function(folder) {
			const children = await this.service.loadFolderNodes(
				this.currentVendorId,
				folder,
				{
					with_virtual_folders: this.virtualFolders
				}
			);
			children
				.filter(child => !child.is_trash)
				.forEach(child => {
					folder.addFolder(child);
				})
		},
		listFolders: function (vendorId, folder) {
			const parameters = []
			parameters['filters'] = {
				with_virtual_folders: this.virtualFolders
			}
			return this.service.getFolderNodes(vendorId, folder, parameters).then(foundNode => foundNode.folders.filter(subfolder => subfolder.hash !== 'trash'))
		},
		listRootFolders: function (vendorId) {
			this.loading = true
			this.folders.clear()
			return this.service
				.getFolder(vendorId, 'root', ['id'])
				.then(root => {
					return this.listFolders(vendorId, root)
				})
				.then(rootFolders => {
					rootFolders.forEach(folder => {
						this.folders.push(folder)
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		selectItem (folder) {
			this.folder = folder
			if (this.returnObject) {
				this.$emit('input', folder)
			} else {
				this.$emit('input', folder.id)
			}
		}
	}
}
</script>
<style>
.selectedElement {
	background-color: rgba(var(--v-primary-transparent), 0.25) !important;
}
</style>
